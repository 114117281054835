import Head from "next/head";
import Image from "next/image";
import FCN from "public/icons/FCN/FCN.png";
import PAB from "public/icons/PAB/PAB.png";
import LNB from "public/icons/LNB/LNB.png";
import CBTX from "public/icons/CBTX/CBTX_logo.svg";
import NCNB from "public/icons/NCNB/NCNB.png";
import ACB from "public/icons/ACB/ACB.png";
import InterfoldLogo from "public/icons/Interfold/interfold.svg";
import React, { useCallback, useEffect, useState } from "react";
import AppButton from "src/components/AppButton/AppButton";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { AppShell, Paper } from "@mantine/core";
import { useRouter } from "next/router";
import { isValidTheme, theme, ThemeObject } from "src/themes/base";
import { applyTheme } from "src/themes/utils";
import { localStorageService } from "src/services/LocalStorageService";
import { useUser } from "@auth0/nextjs-auth0/client";
import { toastService } from "src/services/ToastService";
import CloseIcon from "@mui/icons-material/Close";
import { GetServerSideProps } from "next";
import { initializePino } from "src/backend/dependencies/pino";
import { lenderIdFromRequest } from "src/utils/helpers";

export interface HomePageProps {
  lenderId: keyof ThemeObject;
}

// HACK: Creating a static array of all banker pages
// TODO: Generate a fleshed out list of all banker side pages / routes
// TODO: Find a better spot for this to live...
const BANKER_PAGES = ["/loan-pipeline", "/reporting", "/management", "/welcome-banker"];

const LOAN_PIPELINE_REGEX = /^\/loan-pipeline\/\d+\/annual-review-detail$/;

export default function Home({ lenderId }: HomePageProps) {
  useEffect(() => {
    const themeIdx = isValidTheme(lenderId) ? lenderId : "1";
    applyTheme(theme[themeIdx]);
  }, [lenderId]);

  return (
    <>
      <Head>
        <title>Sign In</title>
        <meta name="description" content="Login to Interfold" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <AppShell padding="md" className="bg-[#F0F1F4]">
        <AppShell.Main>
          <LoginView lenderId={lenderId} />
        </AppShell.Main>
      </AppShell>
    </>
  );
}

interface LenderLogoProps {
  lenderId: keyof ThemeObject;
  onPage: "borrower-login" | "lender-login";
}

export const LenderLogo = ({ lenderId, onPage }: LenderLogoProps) => {
  // TODO: clean this up
  const className = (() => {
    if (
      lenderId === "3" ||
      lenderId === "4" ||
      lenderId === "5" ||
      lenderId === "6" ||
      lenderId === "7" ||
      lenderId === "10"
    ) {
      if (onPage === "borrower-login") {
        return "mx-auto h-[128px] w-auto";
      } else {
        return "mx-auto h-[128px] w-auto text-center flex items-center justify-center";
      }
    }

    if (onPage === "borrower-login") {
      return "mx-auto h-[32px] w-auto";
    } else {
      return "mx-auto h-12 w-auto text-center flex items-center justify-center";
    }
  })();
  if (lenderId === "3") {
    return <Image src={FCN} alt="search" className={className} />;
  } else if (lenderId === "4") {
    return <Image src={PAB} alt="search" className={className} />;
  } else if (lenderId === "5") {
    return <Image src={LNB} alt="search" className={className} />;
  } else if (lenderId === "6") {
    return (
      <div className={"w-full center"}>
        <CBTX className={"w-1/4"} />
      </div>
    );
  } else if (lenderId === "7") {
    return <Image src={ACB} alt="search" className={className} />;
  } else if (lenderId === "10") {
    return <Image src={NCNB} alt="search" className={"w-1/4 h-auto"} />;
  } else {
    return <InterfoldLogo alt="Interfold" height={16} className={className} />;
  }
};

const LoginView = ({ lenderId }: HomePageProps) => {
  localStorageService.setValue("lenderId", lenderId as string);
  const router = useRouter();
  const { user } = useUser();
  const userType = localStorageService.getValue("userType");

  const { error } = router.query;
  const [showError, setShowError] = useState<boolean>(error === "account-not-found");

  const handleBankerLogin = useCallback(() => {
    void router.push("/welcome-banker");
  }, [router]);

  useEffect(() => {
    if (user && userType === "lender") {
      toastService.showInfo("Redirecting to Loan Pipeline");
      void router.push("/loan-pipeline");
    } else if (user && userType === "borrower") {
      toastService.showInfo("Redirecting to Loan Dashboard");
      void router.push("/welcome");
    }
  }, [router, user, userType]);

  // HACK: We are checking if the returnTo value is a bankers page, if so,
  // redirect to the lender login pages.
  useEffect(() => {
    try {
      const currentUrl = new URL(window.location.href);
      const returnToValue = currentUrl?.searchParams?.get("returnTo");

      if (
        (returnToValue && BANKER_PAGES.includes(returnToValue)) ||
        LOAN_PIPELINE_REGEX.test(returnToValue as string)
      ) {
        handleBankerLogin();
      }
    } catch (_) {
      // Log url parsing error
    }
  }, [handleBankerLogin]);

  return (
    <>
      <Paper className={"flex flex-col overflow-hidden"} shadow="xs" p="md" h={"96vh"}>
        {showError && (
          <div className="bg-[#99800033] mx-auto max-w-md py-[16px] px-[10px] border-radius-[8px] relative">
            <CloseIcon className={"absolute right-2 top-2"} onClick={() => setShowError(false)} />
            <div className="flex flex-col items-center">
              <p className="text-center text-gray-700 mb-6">
                Looks like you don&apos;t have an account with Interfold. Please reach out to{" "}
                <a href="mailto:support@interfold.ai" className="text-blue-500 underline">
                  support@interfold.ai
                </a>{" "}
                to create an account.
              </p>
            </div>
          </div>
        )}
        <div className="text-center min-h-full py-12 px-4 sm:px-6 lg:px-8">
          <div className="w-full text-[#5C5C5C]">
            <div>
              <LenderLogo lenderId={lenderId} onPage="borrower-login" />
              <h2 className="text-[27px] mt-[72px] font-bold tracking-tight text-gray-900">
                Borrower Sign In
              </h2>
            </div>
            <div className="mx-auto max-w-md text-[24px]">
              <AppButton
                type="submit"
                theme="primary"
                className="w-[250px] !h-[46px]"
                leftSection={<ArrowCircleRightIcon />}
                onClick={() => {
                  router.push(`/api/auth/login?userType=borrower&lenderId=${lenderId}`);
                }}
              >
                Click to Sign In
              </AppButton>
            </div>
            <div className="mx-auto mt-8 text-[16px]">
              Not a borrower? Access the{" "}
              <span className="text-[#2186BE] underline cursor-pointer" onClick={handleBankerLogin}>
                banker login portal
              </span>{" "}
              instead.
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
};

export const getServerSideProps: GetServerSideProps<HomePageProps> = async (context) => {
  const logger = initializePino();
  const deps = { logger };
  const lenderId = lenderIdFromRequest(deps, context.req);
  return {
    props: {
      lenderId,
    },
  };
};
